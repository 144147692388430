import { defineStore } from 'pinia'
import { State } from '@/pinia/voucher/types'
import { getVoucherList } from '@/api/rewards/reward.service'
import { useShopStore } from '../shop/shop.pinia'
import { Voucher } from '@/pinia/voucher/types'

export const useVoucherStore = defineStore('voucher', {
	persist: false,
	state: (): State => ({
		// selectedVoucher: null,
		voucher: {
			list: [],
			pages: 1,
			limit: 5,
			offset: 1
		},
	}),
	getters: {
		
	},
	actions: {
		async actionGetVoucherList () {
			const shopStore = useShopStore() 
			const payload = {
				shopId: shopStore.selectedShopId as string,
				offset: this.voucher.offset,
				limit: this.voucher.limit
			}
			const response = await getVoucherList(payload)
            
			this.voucher.list = response.data
			this.voucher.pages = response.pagination?.pages
		},
		// async actionOnSelectedVoucher(voucher: Voucher) {
		// 	this.selectedVoucher = voucher
		// }
	}
})
